const frontRoutes = {
  avisoLegal: '/aviso-legal',
  caloriesCalculator: '/calories-calculator',
  cookies: '/cookies',
  contactUs: '/contacto',
  config: '/config/config',
  diet: '/diet',
  dietDetailsMain: '/diet/:id',
  dietsConfig: '/config/diets',
  home: '/',
  ingredients: '/config/ingredients',
  login: '/login',
  logout: '/logout',
  main: '/maraton',
  more: '/more',
  marathonList: '/config/marathons',
  meditationDetails: '/config/meditation/:id',
  meditationDetailsMain: '/meditations/:id',
  meditations: '/meditations',
  meditationsConfig: '/config/meditations',
  payment: '/payment',
  planning: '/config/planning',
  privacyPolicy: '/politica-privacidad',
  progress: '/progress',
  profile: '/profile',
  profileMarathons: '/profile-marathons',
  recipeDetails: '/recipe/:id',
  register: '/register',
  registerGift: '/register?gift=true',
  tasksConfig: '/config/tasks',
  terms: '/terminos-y-condiciones',
  todoList: '/todolist',
  users: '/config/users',
  userPayment: '/user/payment',
  workoutDetails: '/config/workout/:id',
  workoutDetailsMain: '/workouts/:id',
  workouts: '/workouts',
  workoutsConfig: '/config/workouts',
  resetPassword: '/reset-password',
  shoppingList: '/supermarket',
};

export default frontRoutes;
